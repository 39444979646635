.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.highlight {
  background-color: black;
  /* You can change the highlight color here */

}

.highlight:hover {
  color: white
}

.field-container {
  position: relative;
  margin-bottom: 20px;
}

.textarea-floating {
  width: 100%;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  transition: border-color 0.3s ease;
  resize: vertical;
}

.textarea-floating:focus {
  border-color: #6200EA;
  outline: none;
}

.textarea-floating+label {
  position: absolute;
  top: 50%;
  left: 15px;
  font-size: 16px;
  color: #aaa;
  pointer-events: none;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}

.textarea-floating:focus+label,
.textarea-floating:not(:placeholder-shown)+label {
  top: 0;
  font-size: 12px;
  color: #6200EA;
}


.button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background-color: #6200EA;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button:hover {
  background-color: #4B00B5;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.button:active {
  transform: translateY(2px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(98, 0, 234, 0.4);
}

.file-input-container {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 300px;
}

.file-input-label {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background-color: #6200EA;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.file-input-label:hover {
  background-color: #4B00B5;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.file-input-label:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(98, 0, 234, 0.4);
}

.file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}